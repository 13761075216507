@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@font-face {
  font-family: "Sansation";
  src: url("/public/Sansation_Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Sansation";
  src: url("/public/Sansation_Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Sansation Light";
  src: url("/public/Sansation_Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "sacramento";
  src: url("/public/Sacramento-Regular.ttf");
  font-weight: 300;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
